import React from "react";
import { Text, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel,
    Box, useBreakpointValue } from "@chakra-ui/react";
import { List, ListIcon, OrderedList, UnorderedList } from '@chakra-ui/react'    
import { ListItem as ChakraListItem} from '@chakra-ui/react'    
import { PageTitle, ThreeColumnTextBlock, FadeInBoxRight } from './Common1.jsx';


// import {privacyData}  from '../privacy.js';
// import {termsData}  from '../terms.js';
import {loremIpsumData}  from '../loremIpsum.js';
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
// import { SlideDownHeading, FadeInBoxRight } from './Common.jsx';
//import { FadeInBoxLeft } from './Common.jsx';


const ListItem = (props) => {
    return (
        <ChakraListItem
            pt='2px'
            {...props}
        />
    )
}

export default function Rules (props) {

    return (
        <Box m={4} fontSize='0.9rem'>
            <Box fontSize='xl' py={2}>Rules and Regulations</Box>
            <UnorderedList px={2}>
                <ListItem>
                    The tournament will be run in accordance to England Netball Rules.
                </ListItem>
                <ListItem>
                    All matches will be centrally timed, but play starts and stops on the umpires whistle.
                </ListItem>
                <ListItem>
                    Games will be 5 minutes each way with straight change of ends at half time and 2 minutes between matches.
                    <br/>Timings will be strictly adhered to in order to keep to schedule so the teams in each subsequent match.
                    <br/>Please be ready to come on immediately after the conclusion of the preceding match.
                </ListItem>
                <ListItem>
                    Only team coaches and substitutes are allowed on the side of the court during play.
                </ListItem>
                <ListItem>
                    Spectators must be at either end of the court.
                    <br/>Please do not impede the umpires. Please also keep the courts clear of kit and bags etc.
                </ListItem>
                <ListItem>
                    We are hoping to allocate umpires 3 to a court.
                    <br/>If an umpire is registered for the tournament with a team they cannot umpire their own team matches.
                    <br/>The third umpire will score, along with a spare player (of the opposing team if the umpire’s team is playing) or spectator.
                    <br/>Both must stand together.
                </ListItem>
                <ListItem>
                    In the event of a clash of bibs the 2 nd named team will change bibs.
                    <br/>Please ensure you bring a spare set of bibs.
                    <br/>We will try and put spare bibs on each Court in the event that they are needed.
                </ListItem>
            </UnorderedList>

            <Box fontSize='xl' py={2}>Format</Box>
            <UnorderedList px={2}>
                <ListItem>
                    The Competition will have four groups of seven teams.
                    <br/>In the morning all teams will play the other teams in their group.
                </ListItem>
                <ListItem>
                    In the afternoon there will be Cup, Plate and Conference knockout competitions.
                    <br/>Eight teams (the top 2 in each group) go through to the CUP knockout competition.
                    <br/>Sixteen teams (the third, fourth, fifth and sixth placed teams in each group) will progress to the PLATE knockout competition.
                    <br/>Four teams (the seventh placed team in each group) will play for the Conference prize.
                </ListItem>
                <ListItem>
                    Fun dress is permitted/encouraged (in clothing safe to play in).
                    <br/>The more boobs the merrier!
                </ListItem>
            </UnorderedList>

            <Box fontSize='xl' py={2}>Squad</Box>
            <UnorderedList px={2}>
                <ListItem>
                    There is a maximum of 12 people per squad.
                </ListItem>
                <ListItem>
                    Substitutions may be made at half time but must be done as quickly as possible as play is continuous.
                    <br/>There will be no break at half time.
                </ListItem>
                <ListItem>
                    Players can only play for one team, except in the event of an injury where the injured player is within a squad of only 7.
                    <br/>In this event a player can play for a higher team but not lower and must remain with that team for the duration of the tournament.
                </ListItem>
                <ListItem>
                    If a team fails to turn up all teams in that group get a bye with a score of 10-0.
                </ListItem>
            </UnorderedList>

            <Box fontSize='xl' py={2}>Scoring</Box>
            <UnorderedList px={2}>
                <ListItem>
                    Points (in round robin and group stages) will be scored as follows:
                    <UnorderedList px={4}>
                        <ListItem>Win – 5</ListItem>
                        <ListItem>Draw – 3</ListItem>
                        <ListItem>Loss with 50% or more of winning score – 1</ListItem>
                        <ListItem>Loss by more than 50% of winning score - 0</ListItem>
                    </UnorderedList>
                </ListItem>	
                <ListItem>In the event of two teams being level on points at the end of the group matches, final positions will be determined by the following methods:
                    <OrderedList px={4}>
                        <ListItem>Goal difference (goals scored against taken away from goals scored for)</ListItem>
                        <ListItem>Goals scored</ListItem>
                        <ListItem>Head to head result</ListItem>
                        <ListItem>If teams are still tied, the placing will be decided by coin toss.</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    In the event of a draw in any of the last 16, quarter final, semi-final or final matches, the teams will play 2 minutes extra time.
                    <br/>If the score remains tied at the end of extra time, teams continue to play and golden goal comes into play.
                    <br/>This means that the first team to score wins.
                    <br/>The organiser will indicate that play continues with no stoppage.
                </ListItem>
                <ListItem>
                    In the event of a dispute decision of the tournament referee is final.
                </ListItem>
                <ListItem>
                    Captains are to sign both score cards at the end of the game to confirm the result.
                    <br/>Score cards will be collected from the court by runners.
                    <br/>Once the scorecard has been submitted to the Tournament Officials table the result will stand and errors will not be corrected subsequently.
                </ListItem>
            </UnorderedList>

            <Box fontSize='xl' py={2}>Late Arrivals</Box>
            <UnorderedList px={2}>
                <ListItem>
                    No team may play with less than 5 players.</ListItem>
                <ListItem>
                    If a team fails to arrive on time or with less than 5 players the team will forfeit the game 0-10.
                </ListItem>
                <ListItem>
                    If a reserve starts to play because of the late arrival of a player, the reserve must continue to play until half time.
                    <br/>Late arrivals may take the court in a vacant position (only) after a goal is scored and the umpires have been notified.
                    <br/>They must play that position until half time.
                </ListItem>
            </UnorderedList>

            <Box fontSize='xl' py={2}>Captains</Box>
            <UnorderedList px={2}>
                <ListItem>
                    Captains should toss for the centre pass in good time before each game and inform the umpires.
                </ListItem>
                <ListItem>
                    Captains are to sign both scorecards at the end of each match to confirm their agreement as to the result.
                </ListItem>
            </UnorderedList>

            <Box fontSize='xl' py={2}>Accident/Injury</Box>
            <UnorderedList px={2}>
                <ListItem>
                    No extra time is allowed.
                </ListItem>
                <ListItem>
                    The injured player must withdraw immediately so that play is continuous.
                </ListItem>
                <ListItem>
                    It is the responsibility of each team to have first aid dressings at the side of the court for a player that is bleeding.
                    <br/>Bleeding players must leave the court immediately.
                </ListItem>
                <ListItem>
                    In the event of a serious injury, do not move the player from the court.
                    <br/>The player’s safety is paramount.
                    <br/>If time is called the score will stand at the time of the stoppage.
                </ListItem>
                <ListItem>
                    An injured player may return after a goal is scored and the umpires have been notified, returning to the vacant position.
                </ListItem>
                <ListItem>
                    In the case of accident or illness, substitutes must play in the vacant position.
                    <br/>Positions may be changed at half time if required.
                </ListItem>
            </UnorderedList>

            <Box fontSize='xl' py={2}>Umpires</Box>
            <UnorderedList px={2}>
                <ListItem>
                    Umpires are allocated to a court. There are 3 umpires per court and they will rotate each match.
                    <br/>If umpires have been registered as part of a team they may not umpire a match involving that team.
                </ListItem>
                <ListItem>
                    The spare umpire, where able and not playing in another game, shall score, along with a spare player or spectator of the opposing team.
                    <br/>Scorers must stand together at the side line and call the score after each goal.
                </ListItem>
                <ListItem>
                    Please check nails and jewellery for the first 3 rounds.
                    <br/>Taped jewellery is not allowed.
                </ListItem>
                <ListItem>
                    If an umpire receives an injury which results in bleeding the umpires should try and wait until the next interval.
                    <br/>During the intervening period the umpire should avoid touching the ball.
                    <br/>This may mean the other umpire may be asked to take any toss up.
                </ListItem>
            </UnorderedList>

            <Box fontSize='xl' py={2}>General Information for Players, Spectators and Coaches</Box>
            <UnorderedList px={2}>
                <ListItem>
                    Team bases are to be on Court 3, the grass areas in between the netball courts and the hockey pitches or the grass area next to the dome, not around the playing courts.
                </ListItem>                    
                <ListItem>
                    The weather is unpredictable so please plan for sun or rain.
                </ListItem>
                <ListItem>
                    Gazebos are permitted and waterproofs, sun cream and sun hats are recommended.
                </ListItem>
            </UnorderedList>


        

        </Box>
    )
};