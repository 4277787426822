import React, {useState, useEffect} from "react";
import { Input, Flex, Box, Checkbox } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";   
import { PageTitle, ThreeColumnTextBlock, FadeInBoxRight } from './Common1.jsx';
import { GET, PUT, successToast, errorToast, BASE_URL, noop, ADMIN_ROLE } from "../util/constants.js";
import { Denied } from './Home.jsx';
import { restRequest } from "../util/utils.js";



export default function Registration (  {role, cid }, props ) {

    const toast = useToast()
    const [data, setData] = useState([]);

    const [refresh, setRefresh] = useState(0);

    var groupUrl = `${BASE_URL}competition/team/?cid=${cid}&sort={'team_name':-1}&short=True`

    const setDataFromRest = (response) => {
        setData(response)

    };

    const unhappyHandler = (response) => {
        toast({ title:'Registration', description:`${response.detail}`, ...errorToast});
    }

    useEffect(() => {restRequest(GET, groupUrl, setDataFromRest, unhappyHandler);},
        [cid, role, refresh]
    );

    const onValueChange = () => {
        setRefresh(!refresh)
    }

    const handleClick = (te) => {
        const payload={cid:cid, tid:te.team_f_key, checked_in:!te.checked_in}
        restRequest(PUT, `${BASE_URL}competition/team/`, onValueChange, unhappyHandler, payload)
    }

    if ( ![ADMIN_ROLE].includes( role ) ) {
        return (
            <Denied />
        )}

    return (
        <Box mt={6}>
        {
            data.map( (te, index) =>{
                return (
                        <Flex key={index}>
                        <Box key={index} width='20rem' mx={4}>
                                {te.team_name}
                        </Box>
                        <Checkbox
                            size='md'
                            colorScheme='pink'
                            isChecked={te.checked_in}
                            id={te.team_f_key}
                            onChange={ ()=>handleClick(te) }/>
                        </Flex>
                )

            })
        }

        </Box>
    )
};