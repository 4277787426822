import React, { useState, useEffect } from "react";

import { GET, BASE_URL } from "../util/constants.js";
import { Heading, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel,
    Box, useBreakpointValue } from "@chakra-ui/react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faTrophy } from "@fortawesome/free-solid-svg-icons";

// style imports from common
//import { ClWinner, ClRunnerUp,
//  ElWinner, ElRunnerUp } from "../styles/commonStyles.js";

import { restRequest } from "../util/utils.js";

export default function Results( {cid } ) {

    // do the RBAC test
    // const supportedRoles = ALL_ROLES;
    //if (!supportedRoles.includes(currentUser.role)) {
    //  return (<PermissionDenied>You do not have permissions to view this page</PermissionDenied> ) }
    // only continue if allowed

    const [data, setData] = useState({});

    const setResultsFromRest = response => {
        setData( response );
    };

    const resultURL = `${BASE_URL}competition/result/?cid=${cid}`;

    useEffect( () => { restRequest(GET, resultURL, setResultsFromRest);},
        [cid]
    );


    const headings = Object.keys(data);
    
    const bgColors = ['pink.600', 'pink.500', 'pink.400', 'pink.300', 'pink.200', 'pink.100']

    return (
        <>
        {
            headings.map((item, index) => {
                return (
                    <Box mt={6} key={index}>
                        <Heading py={2} px={4} my={2} bg={bgColors[index]} fontSize={['1.0rem', '1.1rem', '1.2rem', '1.4rem']} >
                            {item}
                        </Heading>
                        <Box px={4} py={1} fontSize={['1.0rem', '1.1rem', '1.2rem', '1.4rem']}>
                            {data[item]}
                        </Box>
                    </Box>
                )
            })
        }
        </>
    );
};

// ****************************************************************************
// styles used by this component only
// ****************************************************************************
/*


< Trophy icon={faTrophy} />

export const ResultsValueStyledDiv = styled.div`
  text-align: left;
  font-weight: normal;
  margin: 10px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;

  font-size: 1.4em;
  @media screen and (max-width: 720px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 480px) {
    letter-spacing: -1px;
    font-size: 0.8rem;
  }
`;

const trophy = keyframes`
 50% { opacity: 0; };
`;

export const Trophy = styled(FontAwesomeIcon)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
  /* animation: ${trophy} 4s linear infinite;
  color: goldenrod;
  font-size: 100px;
`; */