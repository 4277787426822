import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, Button} from '@chakra-ui/react';
import { FormLabel, Heading, Box, Input } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
//import { Text, IconButton, Button, Stack, Collapse, Icon, Popover } from '@chakra-ui/react';
//import { PopoverTrigger, PopoverContent, useColorModeValue, useBreakpointValue, useDisclosure } from '@chakra-ui/react';    

import { GET, PUT, POST, USER_INFO, INFO, BASE_URL, ERROR, ALL_ROLES } from "../util/constants.js";
import { successToast, errorToast } from "../util/constants.js";
import { restRequest } from "../util/utils.js";
import { ADMIN_ROLE, TREASURER_ROLE, ADMIN_READ_ONLY_ROLE } from "../util/constants.js";

//import { Link } from '@chakra-ui/react';
// import { Link as ReactLink } from "react-router-dom";

import { Denied } from './Home.jsx';

import { HamburgerIcon } from '@chakra-ui/icons';
// import { CloseIcon } from '@chakra-ui/icons';
// import { ChevronDownIcon, ChevronRightIcon, } from '@chakra-ui/icons';
import { BsFillPersonFill } from 'react-icons/bs';
//import { defaultUser } from "../util/constants.js";

import { useToastHook } from "./Toast.jsx";


/* <HamburgerIcon w={5} h={5} onClick={ () => {setIsOpen(true)} } /> */


export const ActionMenu = ( {cid, cname, role, cstage, setCstage, setSelectedStage} ) => {

    const navigate = useNavigate()
    const toast = useToast()

    if (role !== '__admin_role__') {
        return (
            <Menu bg='white' >
            <MenuButton as={Button} onClick={()=>{navigate('/login')}}>
                <BsFillPersonFill w={8} />
            </MenuButton>
        </Menu>
        )
    }

    const happyHandler = (response) => {
        const trimmedStage = response.stage.slice(2,-2)
        toast({ title:'Competition Next', description:`We are now at stage ${trimmedStage.toUpperCase()}`, ...successToast});
        setCstage(response.stage)
        setSelectedStage(trimmedStage)
        navigate(`/${trimmedStage}`)
    }

    const unhappyHandler = (response) => {
        toast({ title:'Competition Next', description:response.detail, ...errorToast});
    }

    const competitionNext = () => {
        restRequest(POST, `${BASE_URL}competition/next/?cid=${cid}`, happyHandler, unhappyHandler)
    }

    return (
        <Menu bg='white' >
            <MenuButton as={Button}>
                <HamburgerIcon w={8} />
            </MenuButton>
            <MenuList>
                <MenuItem color='#868286' fontWeight='bold' fontSize='.8rem'>{`${cname}: ${cstage.replace(/_/g, " ")}`}</MenuItem>
                <MenuDivider/>
                <MenuItem onClick={()=>{navigate('/switch')}}>Switch</MenuItem>
                <MenuDivider/>
                <MenuItem onClick={()=>{competitionNext()}}>Next</MenuItem>
                <MenuItem onClick={()=>{navigate('/back')}}>Previous</MenuItem>
                <MenuDivider/>
                <MenuItem onClick={()=>{navigate('/report/team')}}>Team Report</MenuItem>
                <MenuItem onClick={()=>{navigate('/report/user')}}>User Report</MenuItem>
                <MenuDivider/>
                <MenuItem onClick={()=>{navigate('/report/referee')}}>Referee Report</MenuItem>
                <MenuDivider/>
                <MenuItem onClick={()=>{navigate('/user-guide')}}>User Guide</MenuItem>
                <MenuItem onClick={()=>{navigate('/logout')}}>Sign Out</MenuItem>
            </MenuList>
        </Menu>
    )

};


export const Back = ( {cid, role, setCstage, setSelectedStage} ) => {

    const navigate = useNavigate()
    const toast = useToast()
    
    const supportedRoles = [ADMIN_ROLE];
    if ( !supportedRoles.includes(role) ) {
        return (
            <Denied />
        )}

    const happyHandler = (response) => {
        const trimmedStage = response.stage.slice(2,-2)
        toast({ title: 'Competition Previous', description: `We are now at stage ${trimmedStage.toUpperCase()}`, ...successToast});
        setCstage(response.stage)
        setSelectedStage(trimmedStage)
        navigate(`/${trimmedStage}`)
    };

    const unhappyHandler = (response) => {
        toast({ title: 'Competition Previous',  description: response.detail,  ...errorToast});
        navigate(-1)
    }

    const competitionPrevious = () => {
        restRequest(POST, `${BASE_URL}competition/previous/?cid=${cid}`, happyHandler, unhappyHandler)
    }

    return (

        <Box pt={'60px'} m={4} pl={[2, 8]} maxW='2xl' >
        <Heading pb={'60px'} color='gray.600' size='md'>Competition Previous</Heading>
        <Box>
            <FormLabel>Press 'Previous' to confirm, or Cancel</FormLabel>
            <Button
                mt={4}
                colorScheme='gray'
                type='submit'
                onClick={() => { competitionPrevious() }}
            >
                Previous
            </Button>
            <Button
                mt={4}
                ml={12}
                colorScheme='gray'
                type='cancel'
                onClick={() => { navigate(-1) }}
            >
                Cancel
            </Button>
        </Box>
        </Box>
    )
};


export const Switch = ( {cid, role, setCstage, setCname} ) => {

    const navigate = useNavigate()
    const [season, setSeason] = useState('2024')    
    const [event, setEvent] = useState('myfc')    
    const [name, setName] = useState('')    

    const handleInputChange = (e) => {
        //console.log(e)
        //setName({...putData, [e.target.id]:e.target.value})
        setName(e.target.value)
        //console.log(putData)
    }

    const handleSubmit = () => {

        if (name === '') {
            return
        };
        setCname(name)
        navigate('/home')
    }

    return (

        <Box pt={'60px'} m={4} pl={[2, 8]} maxW='2xl' >
            <Heading pb={'60px'} color='gray.600' size='md'>Switch Event</Heading>

            <FormLabel>Enter name of competition.</FormLabel>
            <Input id='name' autoFocus={true} type='text' value={name} onChange={handleInputChange} />
        
            
            <Box>
                <FormLabel>Press 'Switch' to confirm, or Cancel</FormLabel>
                <Button
                    mt={4}
                    colorScheme='gray'
                    type='submit'
                    onClick={handleSubmit}
                >
                    Switch
                </Button>
                <Button
                    mt={4}
                    ml={12}
                    colorScheme='gray'
                    type='cancel'
                    onClick={() => { navigate(-1) }}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    )
};