import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Grid, GridItem, Text, Divider, AbsoluteCenter, Icon } from "@chakra-ui/react";
import { InputLeftAddon, InputGroup, Input, InputRightAddon } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";
import { FaPrint } from "react-icons/fa6";
import { useToast } from "@chakra-ui/react";

import { GET, successToast, errorToast, BASE_URL, ERROR, ALL_ROLES, ADMIN_ROLE } from "../util/constants.js";
import { restRequest } from "../util/utils.js";


function PrintIcon() {
    return <Icon as={FaPrint}/>
  }

export default function Match ( { name, cid, match_number, role, group}, props ) {

    const toast = useToast()
    const navigate = useNavigate()
    const [data, setData] = useState([]);

    const [highlight, setHighlight] = useState('');
    const [isPrintView, setIsPrintView] = useState(false)

    const [myFilter, setMyFilter] = useState('')
    const [dataWithFilter, setDataWithFilter] = useState(data)

    const clickHandler = (teamName, matchNumber) => {
        
        if (role === '__admin_role__') {navigate(`/match/${matchNumber}`)}

        if (teamName === highlight) {setHighlight(''); return}
            setHighlight(teamName)
    }

    const matchUrl = `${BASE_URL}competition/match/?cid=${cid}&sort={'match_number':-1}&match_number=${match_number}`
    //if (group) {matchUrl = `${matchUrl}&group=${group}`}

    const getFilterString = () => {
        return (
            window.sessionStorage.getItem(`${name}-match-filter`)
            ?
            JSON.parse( window.sessionStorage.getItem(`${name}-match-filter`))
            :
            ''
        )
    }
  
    const happyHandler = (response) => {
        setData(response)
        setDataWithFilter(response)

    };

    const unhappyHandler = (response) => {
        toast({ title:'Match', description:response.detail, ...errorToast});
    }


    useEffect(() => { 
        const storedFilter = getFilterString()
        setMyFilter(storedFilter); 

        const newArray = data.filter(function (item) {
            return item.team_1_name.toLowerCase().match(storedFilter) || item.team_2_name.toLowerCase().match(storedFilter) || storedFilter === '' || item.referee.join('').toLowerCase().match(storedFilter) || item.pitch.match(storedFilter)
        });
        setDataWithFilter(newArray);
    },
        [data]
    );


    const handleInputChange = (e) => {

        const eTarget = e.target.value.toLowerCase()

        const newArray = data.filter(function (item) {
            return item.team_1_name.toLowerCase().match(eTarget) || item.team_2_name.toLowerCase().match(eTarget) || eTarget === '' || item.referee.join('').toLowerCase().match(eTarget) || item.pitch.match(eTarget)
        });

        setMyFilter(e.target.value);
        setDataWithFilter(newArray);
        window.sessionStorage.setItem(`${name}-match-filter`, JSON.stringify(eTarget))
    };


    useEffect(() => {restRequest(GET, matchUrl, happyHandler, unhappyHandler);},
        [cid, match_number]
    );



    const groupList = [...new Set(data.map(item => item.group))]

    if ( groupList.length === 0) {
        return (
            <Text p={12} color='#e81f81' fontSize='xl' {...props}>
                League Matches not yet created
            </Text>
        )
    };

    return (
        <>
            <Box  m={4} float='right'  onClick={ ()=>{ setIsPrintView(!isPrintView) } } display={(role === '__admin_role__') ? 'block' : 'none'}>
                <PrintIcon width='20px' />
            </Box>
            <Box float='right' width='16rem' p={6}>
                <InputGroup size='sm'>
                <InputLeftAddon fontSize='0.7rem' >filter</InputLeftAddon>
                <Input id='filterText' type='text' value={myFilter} onChange={handleInputChange} fontSize='0.8rem'/>
                <InputRightAddon fontSize='0.8rem' onClick={()=>{setMyFilter('');setDataWithFilter(data);window.sessionStorage.setItem(`${name}-match-filter`, JSON.stringify(''))}}>X</InputRightAddon>
            </InputGroup>
            </Box>
            <Box mt={8} clear='right'></Box>
            <>
            {
            isPrintView
            ? 
            <MatchSlips data={data}/>
            :
            <>
            {
                groupList.map( (groupName, index) =>{
                    return (
                            <Box key={index} maxWidth='40rem' m={4}>
                                <Heading width='100%' fontSize='xl' p={2} my={4}>
                                    {groupName}
                                </Heading>
                                <MatchDetail groupName={groupName} dataWithFilter={dataWithFilter} clickHandler={clickHandler} highlight={highlight} />
                            </Box>
                    )

                })
            }
            </>
            }
            </>
        </>
    )
};

const MatchDetail = ( {groupName, dataWithFilter, clickHandler, highlight} ) => {

    return (
        <>
        {
            dataWithFilter.filter( item => item.group === groupName).map( (match, index) =>{
                return (
                    <Box key={index} >
                    {
                    (match.score_1 < 0) || (match.score_2 < 0)
                    ?
                    <UnplayedMatch
                        match={match}
                        bg={(match.team_1_name === highlight || match.team_2_name === highlight) ? 'pink' : '#f1e8f1'}
                        clickHandler={clickHandler}
                    />
                    :
                    <PlayedMatch
                        match={match}
                        bg={(match.team_1_name === highlight || match.team_2_name === highlight) ? 'pink' : '#f1e8f1'}
                        clickHandler={clickHandler}
                    />
                    }
                    </Box>
                )
            })
        }
        </>
    )
};


const PlayedMatch = ( {match, role, clickHandler, bg}, props ) => {

    const getPensText = (match) => {

        if (match.pens_1 < 0 && match.pens_2 < 0 ) {
            return 'FT'
        }
        if (match.score_1 !== match.score_2) {
            return 'FT'
        }

        const winner = match.pens_1 > match.pens_2 ? match.team_1_name : match.team_2_name

        return (
            `${winner} win ${match.pens_1} - ${match.pens_2} on penalties`
        )
    }

    return (
        <Grid
            m={2} p={2}
            bg={bg}
            borderRadius='8px'
            gridTemplateRows="1.6rem auto"
            gridTemplateColumns="auto 1fr 35px 2px 35px 1fr"
            gridTemplateAreas={`
                "mn t1 s1 bar s2 t2"
                "mn info info info info info"`
            }
        >
            <GridItem pr={2} alignSelf='center' area={'mn'} fontSize='0.8rem'>{match.match_number}</GridItem>
            <GridItem pr={2} justifySelf='end' area={'t1'} onClick={()=>{clickHandler(match.team_1_name, match.match_number)}}>{match.team_1_name}</GridItem>
            <GridItem justifySelf='center' fontSize='1.2rem' fontWeight='bold' area={'s1'}>{match.score_1}</GridItem>
            <GridItem bg='#e81f81' area={'bar'}></GridItem>
            <GridItem justifySelf='center' fontSize='1.2rem' fontWeight='bold' area={'s2'}>{match.score_2}</GridItem>
            <GridItem pl={2} justifySelf='start' area={'t2'} onClick={()=>{clickHandler(match.team_2_name, match.match_number)}}>{match.team_2_name}</GridItem>
            <GridItem justifySelf='center' area={'info'} fontSize='.8rem'>{getPensText(match)}</GridItem>
        </Grid>
    )
};

const UnplayedMatch = ( {match, role, clickHandler, bg}, props ) => {

    const refString = Array.isArray(match.referee) ? match.referee.join(' | ') : match.referee.toString()

    return (
        <Grid
            m={2} p={2}
            bg={bg}
            borderRadius='8px'
            gridTemplateRows="1.6rem auto auto"
            gridTemplateColumns="auto 1fr 35px 2px 35px 1fr"
            gridTemplateAreas={`
                "mn t1 s1 bar s2 t2"
                "mn info info info info info"
                "mn ump ump ump ump ump"`
            }
        >
            <GridItem pr={2} alignSelf='center' area={'mn'} fontSize='0.8rem'>{match.match_number}</GridItem>
            <GridItem pr={2} justifySelf='end' area={'t1'} onClick={()=>{clickHandler(match.team_1_name, match.match_number)}} >{match.team_1_name}</GridItem>
            <GridItem justifySelf='center' fontSize='1.2rem' fontWeight='bold' area={'s1 / bar / s2'}>
                { (match.match_time === 'TBD') ? 'TBD' : match.match_time.slice(10,16) }
            </GridItem>
            <GridItem pl={2} justifySelf='start' area={'t2'} onClick={()=>{clickHandler(match.team_2_name, match.match_number)}} >{match.team_2_name}</GridItem>
            <GridItem justifySelf='center' color='#868286' fontSize='.8rem' area={'info'}>{match.pitch}</GridItem>
            <GridItem justifySelf='center' color='#868286' fontSize='.7rem' area={'ump'}>{refString}</GridItem>
        </Grid>
    )
};


const MatchSlips = ( { data } ) => {

    return (
        <Box ml={4} >
            { data.map( ( match, index ) => (
                <Box key={index} className='keep-together' width='18cm' p={4}>
                    <Grid
                        pt={2}
                        border='1px'
                        width='100%'
                        columnGap='6px'
                        gridTemplateRows="auto auto auto auto auto"
                        gridTemplateColumns="4.5cm 45.cm 4.5cm 4.5cm"
                        gridTemplateAreas={`
                            "matchNo group pitch time"
                            "refHeading refNames refNames initials"
                            "teamOne teamOne scoreOne initialsOne"
                            "vs vs space space"
                            "teamTwo teamTwo scoreTwo initialsTwo"`
                        }
                    >
                        <GridItem area={'matchNo'} justifySelf='center'>
                            {match.match_number}
                        </GridItem>
                        <GridItem area={'group'}>
                            {match.group}
                        </GridItem>
                        <GridItem area={'pitch'}>
                            {match.pitch}
                        </GridItem>
                        <GridItem area={'time'}>
                            {match.match_time.slice(10,16)}
                        </GridItem>
                        <GridItem area={'refHeading'} justifySelf='right' fontSize='.8rem'>
                            referees: 
                        </GridItem>
                        <GridItem area={'refNames'} justifySelf='left' fontSize='.8rem'>
                            {match.referee}
                        </GridItem>
                        <GridItem area={'initials'} fontSize='0.7rem' justifySelf='left' alignSelf='center'>
                            initials
                        </GridItem>
                        <GridItem area={'teamOne'} fontSize='1.2rem' justifySelf='center' alignSelf='center'>
                            {match.team_1_name}
                        </GridItem>
                        <GridItem area={'scoreOne'} justifySelf='center'>
                            <Box w='1cm' h='1cm' bg='#ffe7ea' border='1px' borderColor='black'/>
                        </GridItem>
                        <GridItem area={'initialsOne'}>
                            <Box w='1cm' h='1cm' bg='#ffe7ea' border='1px' borderColor='black'/>
                        </GridItem>
                        <GridItem area={'vs'} justifySelf='center' alignSelf='center'>
                            vs.
                        </GridItem>
                        <GridItem area={'teamTwo'} fontSize='1.2rem'  justifySelf='center' alignSelf='top'>
                            {match.team_2_name}
                        </GridItem>

                        <GridItem mb={8} area={'scoreTwo'} justifySelf='center'>
                            <Box w='1cm' h='1cm' bg='#ffe7ea' border='1px' borderColor='black'/>
                        </GridItem>
                        <GridItem mb={8} area={'initialsTwo'}>
                            <Box w='1cm' h='1cm' bg='#ffe7ea' border='1px' borderColor='black'/>
                        </GridItem>
                    </Grid>

                    <Box position='relative' p={4}>
                        <Divider />
                        <AbsoluteCenter bg='white' px='4' fontSize='0.7rem'>
                            cut here
                        </AbsoluteCenter>
                    </Box>
                </Box>
            ))}
        </Box>
    )
        

};
  

