import React, {useState, useEffect} from "react";
import { Box, Heading, Icon, Flex, Text } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";
import { FaArrowsRotate } from "react-icons/fa6";
import { useToastHook } from "./Toast.jsx";

import { GET, PUT, successToast, errorToast, BASE_URL, noop, ALL_ROLES, ADMIN_ROLE } from "../util/constants.js";
import { restRequest } from "../util/utils.js";


function RotateIcon() {
    return <Icon as={FaArrowsRotate}/>
}


export default function Groups ( { role, cid, cname, group }, props ) {

    const toast = useToast()
    const [data, setData] = useState([]);

    const [teamOne, setTeamOne] = useState({name:'', tid:''});
    const [teamTwo, setTeamTwo] = useState({name:'', tid:''});
    const [swap, setSwap] = useState(0);

    var groupUrl = `${BASE_URL}competition/team/?cid=${cid}&sort={'group_index':-1,'group':-1}&short=True`
    if (group) {groupUrl = `${groupUrl}&group=${group}`}
    //const groupUrl = `${BASE_URL}competition/team/?cid=${cid}&sort={'group_index':-1}`

    const happyHandler = (response) => {
        setData(response)

    };

    const unhappyHandler = (response) => {
        toast({ title:'Group', description:`${response.detail}`, ...errorToast});
    }

    useEffect(() => {restRequest(GET, groupUrl, happyHandler, unhappyHandler);},
        [cname, cid, role, swap, group]
    );


    const handleSwap =() => {
        if (teamOne.tid === '' || teamTwo.tid === '')  {return}
        const payload={cid:cid, tid:teamOne.tid, swap_tid:teamTwo.tid}
        restRequest(PUT, `${BASE_URL}competition/team/`, noop, unhappyHandler, payload)
        setTeamOne({name:'', tid:''});  setTeamTwo({name:'', tid:''})
        setSwap(swap+1)
    }

    const groupList = [...new Set(data.map(item => item.group))]

    if (groupList[0] === '') {
            return (
                <Text p={12} color='#e81f81' fontSize='xl' {...props}>
                    Groups not yet assigned
                </Text>
            )
    };

    if ( groupList.length === 0) {
        return (
            <Text p={12} color='#e81f81' fontSize='xl' {...props}>
                Groups not yet created
            </Text>
        )
    };

    return (
        <>
        <Box  m={4} float='right'  onClick={handleSwap} display={(role === '__admin_role__') ? 'block' : 'none'}>
            <RotateIcon width='20px' />
        </Box>
        <Flex flexWrap='wrap' >
        {
            groupList.map( (groupName, index) =>{
                return (
                        <Box key={index} width='20rem' m={4}>
                            <Heading width='100%' bg='#f1e8f1' fontSize='xl' p={2} my={4} borderRadius='4px'>
                                {groupName}
                            </Heading>
                            <GroupDetails groupName={groupName} data={data} role={role} teamOne={teamOne} setTeamOne={setTeamOne} teamTwo={teamTwo} setTeamTwo={setTeamTwo} />
                        </Box>
                )

            })
        }
        </Flex>
        </>
    )
};

const GroupDetails = ( {groupName, data, role, teamOne, setTeamOne, teamTwo, setTeamTwo} ) => {

 
    const clickHandler = (tid, name) => {
        if (role !== '__admin_role__') return

        if (teamOne.name === '' && teamTwo.name !== name) {setTeamOne({name:name, tid:tid}); return}
        if (teamTwo.name === '' && teamOne.name !== name) {setTeamTwo({name:name, tid:tid}); return}
        if (teamOne.name === name) {setTeamOne({name:'', tid:''}); return}
        if (teamTwo.name === name) {setTeamTwo({name:'', tid:''}); return}
        setTeamTwo({name:name, tid:tid})
    }

    return (
        <>
        {
            data.filter( item => item.group === groupName).map( (teamEntry, index) =>{
                return (
                    <Flex key={index} wrap='nowrap' gap={4} 
                        outline={ (teamEntry.team_name===teamOne.name || teamEntry.team_name===teamTwo.name) ? '1px solid #e81f81' : 'none'} 
                        onClick={()=>clickHandler(teamEntry.team_f_key, teamEntry.team_name)}
                    >
                        <Box pl={2} width ='32px'>
                            {teamEntry.group_index}
                        </Box>
                        <Box >
                            {teamEntry.team_name}
                        </Box>
                    </Flex>
                )
            })
        }
        </>
    )
};