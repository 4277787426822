import { React } from 'react';
import { Link } from "react-router-dom";

import { Box, chakra, Stack, Text, Image, Flex, useColorModeValue, VisuallyHidden } from '@chakra-ui/react';


import EnglandNetball from "../img/englandNetball.png";
import LawnNetball from "../img/LawnNetball.png";
//import fdLogo from '../img/fd_fulllogo_col-blk.JPG';
  
  
const SocialButton = ( {children, label, href, target} ) => {

    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            target={target}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
                {children}
        </chakra.button>
    );
};
  
export default function Footer() {
    return (
        <Box>
            <Flex
                width='100%'
                pt={4}
                pb={8}
                px={[2,4,8,8]}
                direction='row'
                spacing={8}
                justify={{ base: 'center', sm: 'space-between', md: 'space-between', lg: 'space-between' }}
                align='flex-end'
            >
                <a href='https://www.englandnetball.co.uk/' target='_blank' rel='noreferrer'>
                    <Image w='120px' src={EnglandNetball} alt='England Netball'/>
                </a>
                  
                <a href='mailto:gemmafeatonby@hotmail.com'>
                    <Text fontSize='.9rem' color='grey'
                        _hover={{
                            cursor: 'pointer',
                            color: 'black'
                        }}
                    >
                        email me
                    </Text>
                </a>

                <a href='https://lawnnetballclub.co.uk/' target='_blank' rel='noreferrer'>
                    <Image w='60px' src={LawnNetball} alt='Lawn Netball'/>
                </a>
            

            </Flex>
        </Box>
    );
}


/*
<SocialButton label={'email'} href={'mailto:katherine@ksfdyslexiaconsulting.co.uk'} target={'_blank'}>
  <FaEnvelope />
</SocialButton>
*/