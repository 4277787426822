import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Heading, Icon, Input, Button, FormLabel, HStack } from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";
import { FaArrowsRotate } from "react-icons/fa6";

import { GET, PUT, successToast, errorToast, INFO, BASE_URL, noop, ALL_ROLES, ADMIN_ROLE } from "../util/constants.js";
import { restRequest } from "../util/utils.js";

import { Denied } from './Home.jsx';

function RotateIcon() {
    return <Icon as={FaArrowsRotate}/>
  }

export default function EditMatch ( { role, cid, cname }, props ) {

    const navigate = useNavigate()
    const toast = useToast()
    const params = useParams();
    const [scoreOrDetail, setScoreOrDetails] = useState(true);
    const [putData, setPutData] = useState({score_1: -999, score_2: -999})
    const [names, setNames] = useState({teamOne: '', teamTwo:''})

    const matchNumber = params.matchNumber

    const matchUrl = `${BASE_URL}competition/match/?cid=${cid}&match_number=${matchNumber}`

    const happyHandler = (response) => {

        setPutData({
            score_1: (response[0].score_1 < 0) ? '' : response[0].score_1,
            score_2: (response[0].score_2 < 0) ? '' : response[0].score_2,
            pitch: response[0].pitch,
            referee_1: response[0].referee[0],
            referee_2: response[0].referee[1],
            match_time: response[0].match_time,
            match_number: response[0].match_number,
            cid: response[0].comp_f_key,
        })
        setNames({teamOne: response[0].team_1_name, teamTwo:response[0].team_2_name})
    };

    const unhappyHandler = (response) => {
        toast({ title:'Match', description:`${response.detail}`, ...errorToast});
    }

    useEffect(() => {restRequest(GET, matchUrl, happyHandler, unhappyHandler);},
        [cid, role]
    );

    if ( ![ADMIN_ROLE].includes( role ) ) {
        return (
            <Denied />
        )}

    const handleInputChange = (e) => {
        //console.log(e)
        setPutData({...putData, [e.target.id]:e.target.value})
        //console.log(putData)
    }

    const happySubmit = (response) => {
        navigate(-1)
    }

    const handleSubmit = () => {
        putData.referee = [putData.referee_1 ? putData.referee_1 : '' , putData.referee_2 ? putData.referee_2 : '']
        restRequest(PUT, matchUrl, happySubmit, unhappyHandler, putData);
    }

    return (
        <>
        <Box  m={4} float='right'  onClick={()=>{setScoreOrDetails(!scoreOrDetail)}} display={(role === '__admin_role__') ? 'block' : 'none'}>
            <RotateIcon width='20px' />
        </Box>
        <Box>
            {
                (scoreOrDetail)
                ?
                <Score names={names} putData={putData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
                :
                <Detail putData={putData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
            }
        </Box>
        </>
    )
};


const Score = ( { names, putData, handleInputChange, handleSubmit } ) => {

        const navigate = useNavigate()

        return (
            <Box pt={'60px'} m={4} pl={[2, 8]} maxW={useBreakpointValue({ base: 'l', md: 'xl' })}>
                <Heading pb={'60px'} color='pink' size='md'>Enter match score for {putData.match_number}</Heading>
                <FormLabel>{names.teamOne} score</FormLabel>
                <Input mb={4} id='score_1' autoFocus={true} size='md' type='integer' value={putData.score_1} onChange={handleInputChange} />
                <FormLabel>{names.teamTwo} score</FormLabel>
                <Input mb={4} id='score_2' type='integer' size='md' value={putData.score_2} onChange={handleInputChange} />
                <Button
                    mt={4}
                    colorScheme='gray'
                    type='submit'
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                <Button
                    mt={4}
                    ml={12}
                    colorScheme='gray'
                    type='cancel'
                    onClick={() => { navigate(-1) }}
                >
                    Cancel
                </Button>
            </Box>
          )
};



const Detail = ( { putData, handleInputChange, handleSubmit } ) => {
    const navigate = useNavigate()

    return (
        <Box pt={'60px'} m={4} pl={[2, 8]} maxW={useBreakpointValue({ base: 'l', md: 'xl' })}>
            <Heading pb={'60px'} color='pink' size='md'>Edit match details for {putData.match_number}</Heading>
            <FormLabel>Start time</FormLabel>
            <Input mb={4} id='match_time' autoFocus={true} size='md' type='text' value={putData.match_time} onChange={handleInputChange} />
            <FormLabel>Court</FormLabel>
            <Input mb={4} id='pitch' type='text' size='md' value={putData.pitch} onChange={handleInputChange} />
            <FormLabel>Umpires</FormLabel>
            <HStack>
                <Input mb={4} id='referee_1' type='text' size='md' value={putData.referee_1} onChange={handleInputChange} />
                <Input mb={4} id='referee_2' type='text' size='md' value={putData.referee_2} onChange={handleInputChange} />
            </HStack>
            <Button
                mt={4}
                colorScheme='gray'
                type='submit'
                onClick={handleSubmit}
            >
                Submit
            </Button>
            <Button
                mt={4}
                ml={12}
                colorScheme='gray'
                type='cancel'
                onClick={() => { navigate(-1) }}
            >
                Cancel
            </Button>
        </Box>
      )
};


