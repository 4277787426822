import React, {useState} from "react";
import { Link, } from "react-router-dom";
// import { UnorderedList, ListItem, Flex, Box, useBreakpointValue } from "@chakra-ui/react";
import { Box, Flex  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
// import { SlideDownHeading, FadeInBoxRight } from './Common.jsx';
//import { FadeInBoxLeft } from './Common.jsx';

import { noop } from "../util/constants.js";


export function AgeBar (props) {

    return (
        <Box>
            Age Bar
        </Box>
    )
};


const StageButton = ( {label, setSelectedStage, selectedStage} ) => {

    return (
        <Link to={label.replace(/__/g, "")} style={{all: 'unset'}}>
            <Box
                cursor='pointer'
                padding='5px'
                fontSize='1.2em'
                borderBottom={(selectedStage === label.replace(/__/g, "")) ? 'solid 3px #ed1f81' : 'none'}
                onClick={() => {setSelectedStage(label.replace(/__/g, ""));}}>
                {label.replace(/_/g, " ")}
            </Box>
        </Link>
    );
  
  };


export default function StageBar ( {role, cstages, selectedStage, setSelectedStage} ) {

    const getStages = (role) => {
        if (role === '__admin_role__') return ["home", "rules", "competition", "registration", ...cstages, "sponsors"];
        if (role === '__guest_role__') return ["home", "rules", ...cstages, "sponsors"];
        return ["home", "rules", ...cstages, "sponsors"]; // coach or any other user
    }    

    return (
        <Flex
            wrap='nowrap'
            bg='#000000'
            color='#ffffff'
            padding='5px'
            whiteSpace='nowrap'
            paddingLeft='10px'
            margin='0'
            scrollBehavior='smooth'
            overflowX='auto'
        >
            <>
            {getStages(role).map((item, index) => (
                <StageButton
                    key={index}
                    label={item}
                    setSelectedStage={setSelectedStage}
                    selectedStage={selectedStage}
                />
            ))}
            </>
        </Flex>
  )
};

