import React, {useState, useEffect} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Box, Heading, Icon, Flex, Text } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";
import { FaArrowsRotate } from "react-icons/fa6";
import { useToastHook } from "./Toast.jsx";

import { GET, PUT, successToast, errorToast, BASE_URL, noop, ALL_ROLES, ADMIN_ROLE, TREASURER_ROLE, ADMIN_READ_ONLY_ROLE } from "../util/constants.js";
import { restRequest } from "../util/utils.js";

import { Denied } from './Home.jsx';

function RotateIcon() {
    return <Icon as={FaArrowsRotate}/>
  }

export default function Competition ( { role, cid, }, props ) {

    const toast = useToast()
    const navigate = useNavigate()
    const [meta, setMeta] = useState({});
    const [data, setData] = useState([]);

    const compUrl = `${BASE_URL}competition/?cid=${cid}`

    const metaHandler = (response) => {
        setMeta(response[0])

    };

    const dataHandler = (response) => {
        setData(response[0])

    };

    const unhappyHandler = (response) => {
        toast({ title:'Competition', description:`${response.detail}`, ...errorToast});
    }

    useEffect(() => {
        restRequest(GET, `${compUrl}&meta=true`, metaHandler, unhappyHandler);
        restRequest(GET, compUrl, dataHandler, unhappyHandler);
        },
        [cid, role]
    );

    if ( ![ADMIN_ROLE].includes( role ) ) {
        return (
            <Denied />
        )}

    const handleSwap =() => {
        console.log("here")
    }

    const headingList = Object.keys(meta)

    if (headingList.length === 0) {
            return (
                <Text p={12} color='#e81f81' fontSize='xl' {...props}>
                    loading
                </Text>
            )
    };

    return (
        <>
        <Box  m={4} float='right'  onClick={handleSwap} display={(role === '__admin_role__') ? 'block' : 'none'}>
            <RotateIcon width='20px' />
        </Box>
        <Flex flexWrap='wrap' >
        {
            headingList.map( (heading, index) =>{
                return (
                        <Box key={index} width='30rem' m={4}>
                            <Heading width='100%' bg='#f1e8f1' fontSize='lg' p={2} my={4} borderRadius='4px'>
                                {heading}
                            </Heading>
                            <CompDetails headingData={meta[heading]} data={data} />
                        </Box>
                )

            })
        }
        </Flex>
        </>
    )
};

const CompDetails = ( {headingData, data} ) => {

    return (
        <>
        {
            headingData.map( (item, index) =>{
                return (
                    <Flex key={index} wrap='nowrap' gap={4} justify='space-between'
                    >
                        <Box pl={2}>
                            {item}
                        </Box>
                        <Box>
                            {data[item]?.toString()}
                        </Box>
                    </Flex>
                )
            })
        }
        </>
    )
};

