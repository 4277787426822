import React from "react";
import { Text, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel,
    Box, useBreakpointValue } from "@chakra-ui/react";
import { List, ListItem, ListIcon, OrderedList, UnorderedList } from '@chakra-ui/react'    
import { PageTitle, ThreeColumnTextBlock, FadeInBoxRight } from './Common1.jsx';


// import {privacyData}  from '../privacy.js';
// import {termsData}  from '../terms.js';
import {loremIpsumData}  from '../loremIpsum.js';
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
// import { SlideDownHeading, FadeInBoxRight } from './Common.jsx';
//import { FadeInBoxLeft } from './Common.jsx';


export default function Home (props) {

    return (
        <Box m={4}>
            <Box p={2} fontSize='2xl'>
                Lawn Netball Club
            </Box>

            <Box p={2} fontSize='2xl'>Charity Tournament for Coppafeel!</Box>
            <Box p={2} >Dorcan Recreation Complex, Swindon SN3 5DA</Box>
            <Box p={2} >Sunday 30 June 2024<br/>Registration is at <b>9:00am</b>. Play starts at 9:30am.<br/>There will be a meeting for Umpires, Captains, and Managers at 9.15am.</Box>
            <Box p={2} >Welcome to the Lawn Netball Club Charity Tournament for Coppafeel!<br/>We do hope that you enjoy your day here with us.</Box>
            <UnorderedList p={2}>
                <ListItem>Car parking is available to the front and rear of the recreation complex for cars.
                    <br/>Please do not park in the residential streets surrounding the complex.</ListItem>
                <ListItem>Toilets are available in the leisure centre – upstairs next to the sports hall or in the girls changing rooms inside the centre.</ListItem>
                <ListItem>
                    Refreshments are available throughout the day in the room to the rear of the Dome, along with a raffle and Lottertitty and glitter.<br/>We will have toasties, sandwiches/rolls, crisps, savoury and sweet goodies.
                    <br/>Please bring cash or you will be able to scan a QR code to pay on the day to help us raise as much money as possible for Coppafeel!
                </ListItem>
                <ListItem>First aid is available from the leisure centre but we do encourage teams to bring their own first aid kits, including plasters, ice packs and tape.</ListItem>
            </UnorderedList>
        </Box>
    )
};


export const Terms = (props) => {

    return (
        <>
        <PageTitle>Terms And Conditions</PageTitle>
        <FadeInBoxRight>
            <ThreeColumnTextBlock data={loremIpsumData} mainTitle={null} />
        </FadeInBoxRight>
        </>
    )
}; 

export const Privacy = (props) => {

    return (
        <>
        <PageTitle>Privacy</PageTitle>
        <FadeInBoxRight>
            <ThreeColumnTextBlock data={loremIpsumData} mainTitle={null} />
        </FadeInBoxRight>
        </>
    )
};
  


export const PageNotFound = (props) => {
    return (
        <Text {...props}>
        Page not found.
        </Text>
    )
};

export const Blank = ({text}, props) => {
    return (
        <Text p={12} fontSize='2xl' {...props}>
        {text}
        </Text>
    )
};

export const Denied = ({text}, props) => {
    return (
        <Text p={12} color='#e81f81' fontSize='2xl' fontWeight='bold' {...props}>
            You do not have permission to view this page
        </Text>
    )
};