import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { HStack, Button } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'  
import { Text, Heading, Box, Input, Icon } from '@chakra-ui/react' 
import { useDisclosure } from '@chakra-ui/react'

import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton } from '@chakra-ui/react'

import { FaTrashCan } from "react-icons/fa6";

import { mongoTest } from "../util/mongoTest.js";
//import { Heading, Button, Button, Box  } from "../styles/commonStyles.js";
import { GET, BASE_URL, POST, PUT, errorToast, DELETE, GUEST_ROLE, ADMIN_ROLE } from "../util/constants.js";
import { restRequest } from "../util/utils.js";


function TrashIcon() {
    return <Icon as={FaTrashCan}/>
}



// *****************
//  StandardEditAttributes
// *****************

export default function StandardEditAttributes( {
    getUrl,  putUrl, deleteUrl, postUrl=putUrl, doItMethod=PUT, myDefaultValue='', structure={}, title=null, subtitle=null, BackButton=null, restMustContain={} }) {

    // structure = {field: [title, type, editable, mandatory], ...}
    // props
    // doItMethod = verb to use on update
    const navigate = useNavigate()
    const toast = useToast()

    const fields = Object.keys(structure);

    const itemDefault = {}
    for (const key of fields) { itemDefault[key] = myDefaultValue;}

    const [originalData, setOriginalData] = useState( itemDefault );
    const [changedData, setChangedData] = useState( itemDefault );
    
    const [trackEdit, setTrackEdit] = useState('');
    const [showSaveButton, setShowSaveButton] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()


    const setDataFromRest = (response) => {
        setOriginalData(response[0])
        setChangedData(response[0]);
    };

    const unhappyHandler = (response) => {
        toast({ title:'Edit Data', description:response.detail, ...errorToast});
        navigate(-1)
    }

    const setBlankData = () => {
        setOriginalData( itemDefault );
        setChangedData( itemDefault );
    };

    useEffect(() => { restRequest(GET, getUrl, setDataFromRest, unhappyHandler);
        }, []
    );

    const handleEditClick = (field) => {
        if (trackEdit[field] !== field && structure[field][2]) {
            setTrackEdit(field)
        }
    }

    const handleChange = (e) => {
        setChangedData({...changedData, [e.target.id]: e.target.value});
        setShowSaveButton(true);
    }

    const handleDiscardChanges = () => {
        setChangedData(originalData);
        setShowSaveButton(false);
        setTrackEdit('');
    }


    const handleSaveChanges = () => {
        //create a diff Object
        const diffData = restMustContain
        for (const key of fields) {
            if (originalData[key] !== changedData[key]) {
                diffData[key] = changedData[key];
            }
        }
        //if diff is "something" put to admin update REST
        if (Object.keys(diffData).length > 0) {
            if (Object.keys(diffData).includes('username')) {
                diffData.new_username = diffData.username;
                diffData.username = originalData.username;
            }
            
            console.log(diffData)
            restRequest(doItMethod, putUrl, unhappyHandler, unhappyHandler, diffData )
        }
        setShowSaveButton(false);
        setTrackEdit('');
    }

    const fmtC = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });

    const applyFormatting = ( originalData, fieldName ) => {
        if (structure[fieldName][1] === 'currency') {
            return (fmtC.format(originalData/100))
        }
        if (structure[fieldName][1] === 'integer') {
            return (originalData)
        }
        if (originalData === '') {
            return ('---')
        }
        return originalData.toString();
    };

    const handleDelete = () => {
        onClose()
        restRequest(DELETE, deleteUrl, setDataFromRest, unhappyHandler)
        navigate(-1)

    }


    const myBackButton = ( label ) => {

        return (
        <>
        {
            label
            ?
            <Button onClick={ () => {navigate(-1);} }>
                {label}
            </Button>
            :
            null
        }
        </>
        )
    };


    return (
        <>
        <Box m={6} float='right' onClick={onOpen} ><TrashIcon width='20px'  /></Box>
        {
            title
            ?
            <Heading fontSize='1.2rem' m={6}>{title}</Heading>
            :
            null
        }
        <Text>{subtitle}</Text>
        {
        fields.map((field, index)=>{
            return (
                <HStack key={index} maxWidth='50rem'>
                    <Box w='30%' fontSize='0.9rem' fontWeight='bold' mt={2} pr={0} textAlign='right'>
                        {structure[field][0]}
                    </Box>
                    <Box id={field} onClick={() => handleEditClick(field)} w='70%' fontSize='0.9rem' textAlign='left' pl={2}>
                        {
                        trackEdit === field
                        ?
                        <Input id={field} autoFocus={true} pl={2} value={changedData[field]} onChange={handleChange} size='sm'></Input>
                        :
                        applyFormatting(changedData[field], field)
                        }
                    </Box>
                </HStack>
            )
        })
        }
        {
        (showSaveButton === true)
        ?
        <>
        <Button m={6} onClick={ () => {handleSaveChanges();} }>save</Button>
        <Button m={6} onClick={ () => {handleDiscardChanges();} }>discard</Button>
        </>
        :
        <BackButton/>
        }


        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Delete Confirmation !
                    </AlertDialogHeader>
        
                    <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>
        
                    <AlertDialogFooter>
                        <Button onClick={handleDelete}>
                            Continue
                        </Button>
                        <Button ref={cancelRef} colorScheme='pink' onClick={onClose} ml={3}>
                            Cancel
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>

        </>
    )
};


const ConfirmDelete = ({onClickCancel, onClickContinue}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
  
    return (

      <>
 
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Delete Confirmation !
                    </AlertDialogHeader>
        
                    <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>
        
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='pink' onClick={onClose} ml={3}>
                            Continue
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

