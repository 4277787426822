export const noop = () => {};

export const isTouchScreen = (window.matchMedia("(any-pointer: coarse)").matches) ? true : false;

export const successToast = {status: "success", position:'top', duration:6000, isClosable:true};
export const errorToast = {status: "error", position:'top', duration:6000, isClosable:true};

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
export const BASE_URL = '/api/';

export const GUEST_ROLE = "__guest_role__";
export const ADMIN_ROLE = "__admin_role__";
export const COACH_ROLE = "__coach_role__";
export const ADMIN_READ_ONLY_ROLE = "__admin_read_only_role__";
export const REFEREE_ROLE = "__referee_role__";
export const TREASURER_ROLE = "__treasurer_role__";
export const TEAM_ADMIN_ROLE = "__team_admin_role__";
export const CLUB_SECRETARY_ROLE = "__club_secretary_role__";
export const USER_ROLE = "__user_role__";


export const COMP_FORMAT_CHAMPIONS_LEAGUE = "__champions_and_europa_leagues__";
export const COMP_FORMAT_32_TEAM_CHAMPIONS_LEAGUE = "__32_team_champions_and_europa_leagues__";
export const COMP_FORMAT_SINGLE_PHASE_FESTIVAL = "__single_phase_festival__";
export const COMP_FORMAT_TWO_PHASE_FESTIVAL = "__two_phase_festival__";

export const VALID_COMP_TYPES = [
    COMP_FORMAT_CHAMPIONS_LEAGUE,
    COMP_FORMAT_32_TEAM_CHAMPIONS_LEAGUE,
    COMP_FORMAT_SINGLE_PHASE_FESTIVAL,
    COMP_FORMAT_TWO_PHASE_FESTIVAL
  ];


const pallette = {
    darkBlue: '#282355',
    pink: '#e81f81',
    gray: '#868286',
    lightPink: '#f1e8f1',
}  
  
export const BUFF = `#fff8dc`;

export const CYAN = {
    50: `#EDFDFD`,
    100: `#C4F1F9`,
    200: `#9DECF9`,
    300: `#76E4F7`,
    400: `#0BC5EA`,
    500: `#00B5D8`,
    600: `#00A3C4`,
    700: `#0987A0`,
    800: `#086F83`,
    900: `#065666`,
}
