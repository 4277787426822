import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { Button, Box, useBreakpointValue } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
// import { SlideDownHeading, FadeInBoxRight } from './Common.jsx';
import { GET, PUT, successToast, errorToast, BASE_URL, noop, ALL_ROLES, ADMIN_ROLE } from "../util/constants.js";
import { restRequest } from "../util/utils.js";
import { Denied } from './Home.jsx';
import StandardEditAttributes from './CommonEditAttributes.jsx';


// *****************
//  TeamAdmin
// *****************

export default function TeamAdmin ( { cid, role } ) {

    const navigate = useNavigate()
    const params = useParams()
    const tid = params.tid

    /*    
    const toast = useToast()

    const [teamEntry, setTeamEntry] = useState({})


    const happyHandler = (response) => {
        setTeamEntry(response[0])
    };

    const unhappyHandler = (response) => {
        toast({ title:'Edit Team', description:response.detail, ...errorToast});
    }

    useEffect(() => {restRequest(GET, getUrl, happyHandler, unhappyHandler);},
        [role]
    );

    */

    if ( ![ADMIN_ROLE].includes( role ) ) {
        return (
            <Denied />
        )}

        
    const BackButton = () => {
        return (<Button m={6} onClick={ () => { navigate('/report/team');} }>back</Button>)
    }
    

    // structure = {field: [title, type, editable, mandatory], ...}
    const structure = {
        team_f_key: ['id', 'string', false, false],
        team_name: ['team name', 'string', true, false],
        status: ['team status', 'string', true, false],
        group: ['group', 'string', true, false],
        group_index: ['group index', 'string', true, false],
        checked_in: ['checked in', 'boolean', false, false],
      }

   
    return (
        <>
        < StandardEditAttributes
            getUrl={`${BASE_URL}competition/team/?cid=${cid}&tid=${tid}`}
            putUrl={`${BASE_URL}competition/team/`}
            postUrl={`${BASE_URL}competition/team/`}
            deleteUrl={`${BASE_URL}competition/team/?cid=${cid}&tid=${tid}`}
            structure={structure}
            title={'edit team data'}
            BackButton={BackButton}
            restMustContain={{cid: cid, tid: tid}}
        />
        </>
    )
    
};


// *****************
//  RefereeAdmin
// *****************

export function RefereeAdmin ( { cid, role } ) {

    const navigate = useNavigate()
    const params = useParams()
    const rid = params.rid

    /*    
    const toast = useToast()

    const [teamEntry, setTeamEntry] = useState({})


    const happyHandler = (response) => {
        setTeamEntry(response[0])
    };

    const unhappyHandler = (response) => {
        toast({ title:'Edit Team', description:response.detail, ...errorToast});
    }

    useEffect(() => {restRequest(GET, getUrl, happyHandler, unhappyHandler);},
        [role]
    );

    */

    if ( ![ADMIN_ROLE].includes( role ) ) {
        return (
            <Denied />
        )}

        
    const BackButton = () => {
        return (<Button m={6} onClick={ () => { navigate('/report/referee');} }>back</Button>)
    }
    

    // structure = {field: [title, type, editable, mandatory], ...}
    const structure = {
        id: ['id', 'string', false, false],
        name: ['referee name', 'string', true, false],
        level: ['level', 'string', true, false],
        club: ['club', 'string', true, false],
        playing_for: ['playing for', 'string', true, false],
        type: ['type', 'string', true, false],
      }

   
    return (
        <>
        < StandardEditAttributes
            getUrl={`${BASE_URL}competition/referee/?rid=${rid}`}
            putUrl={`${BASE_URL}competition/referee/`}
            postUrl={`${BASE_URL}competition/referee/`}
            deleteUrl={`${BASE_URL}competition/referee/?search={'id':'${rid}'}`}
            structure={structure}
            title={'edit referee data'}
            BackButton={BackButton}
            restMustContain={{rid: rid}}
        />
        </>
    )
    
};

