import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Flex, Box, Heading, UnorderedList } from '@chakra-ui/react'    
import { Image as ChakraImage } from '@chakra-ui/react'    

//import { Logo } from "../styles/header.js";

//import { BASE_URL, ADMIN_ROLE, TREASURER_ROLE, ADMIN_READ_ONLY_ROLE } from "../util/constants.js";
import { isTouchScreen } from "../util/constants.js";
//import { CommonHeading, CommonContentWarningMessage } from "../styles/commonStyles.js";

import HainesWatts from "../img/HainesWatts.jpg";
import InsuranceWith from "../img/InsuranceWith.png";
import Monahans from "../img/Monahans.jpg";
import GillySalter from "../img/GillySalterSports.jpg";
import HollyBlue from "../img/HollyBlue.jpg";


const Image = (props) => {
    return (
        <ChakraImage
            p={4}
            opacity={isTouchScreen ? '1.0' : '0.6'}
            _hover={{
                opacity: 1.0
            }}
            w='250px'
            {...props}
        />
    )
}

// *****************
//  Sponsors
// *****************


export default function Sponsors(props) {

    return (
        <>
        <Heading m={10} fontSize='1.2rem' color='#ed1f81'>Many thanks to our Sponsors</Heading>
        <Flex
            maxWidth='1000px'
            justifyContent='center'
            alignItems='center'
            rowGap='30px'
            columnGap='20px'
            flexWrap='wrap'
        >

            <Box>
                <a href='https://www.hwca.com/' target={'_blank'} rel='noreferrer'>
                    <Image src={HainesWatts} />
                </a>
            </Box>

            <Box>
                <a href='https://www.insurancewith.com/' target={'_blank'} rel='noreferrer'>
                    <Image src={InsuranceWith} />
                </a>
            </Box>

            <Box>
                <a href='https://www.monahans.co.uk/' target={'_blank'} rel='noreferrer'>
                    <Image src={Monahans} />
                </a>
            </Box>
            
            <Box>
                <a href='https://www.gillysaltersports.co.uk/' target={'_blank'} rel='noreferrer'>
                    <Image src={GillySalter} />
                </a>
            </Box>
            
            <Box>
                <Image w='200px' src={HollyBlue} />
            </Box>

        </Flex>
        </>
    )
}