import React, { useEffect } from "react";
import {
  FormControl, FormLabel, Input, FormHelperText, FormErrorMessage,
  Button, Heading, Box, useBreakpointValue
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import { useToastHook } from "./Toast.jsx";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';

import { restRequest } from "../util/utils.js";
import { POST, BASE_URL, successToast, errorToast } from "../util/constants.js";
import { useNavigate } from "react-router-dom";



export function LogIn({ setUser, setRole, setUid, setSelectedStage }, props) {

    const [userName, setUserName] = React.useState('admin')
    const [password, setPassword] = React.useState('')

    const [isUserNameError, setIsUserNameError] = React.useState(false);
    const [isPasswordError, setIsPasswordError] = React.useState(false)

    const toast = useToast()
    const navigate = useNavigate()


    const handlePasswordInputChange = (e) => {
        setPassword(e.target.value)
        setIsPasswordError(false)
    }

    const handleUserNameInputChange = (e) => {
        setUserName(e.target.value)
        setIsUserNameError(false)
    }

    const happyHandler = (response) => {
        toast({ title:'Sign In', description:`${response.username} sign-in succcessful`, ...successToast});
        setUserName('');
        setPassword('');
        setUser(response.username);
        setRole(response.role);
        setUid(response.id);
        window.sessionStorage.setItem('accessToken', response.accessToken);
        setSelectedStage('home')
        navigate('/home')
    }

    const unhappyHandler = (response) => {
        toast({ title:'Sign In', description:response.detail, ...errorToast});
    }

    const handleSubmit = () => {

        if (userName === '') {
            setIsUserNameError(true)
            return
        }
        setIsUserNameError(false)

        if (password === '') {
            setIsPasswordError(true)
            return
        }
        setIsPasswordError(false)

        restRequest(POST, `${BASE_URL}user/logon/`, happyHandler, unhappyHandler, { username: userName, password: password })

    };

  return (
    <Box pt={'60px'} m={4} pl={[2, 8]} maxW={useBreakpointValue({ base: 'xl', md: '3xl' })}>
      <Heading pb={'60px'} color='gray.600' size='md'>Sign-in</Heading>
      <FormControl isInvalid={isUserNameError || isPasswordError} {...props}>
        <FormLabel>Username</FormLabel>
        <Input id='username' type='text' value={userName} onChange={handleUserNameInputChange} />
        {!isUserNameError ? (
          <FormHelperText pb={8} >
            Enter your username.
          </FormHelperText>
        ) : (
          <FormErrorMessage pb={8} >Cannot be blank.</FormErrorMessage>
        )}
        <FormLabel>Password</FormLabel>
        <Input id='password' type='password' value={password} autoFocus={true} onChange={handlePasswordInputChange} />
        {!isPasswordError ? (
          <FormHelperText>
            Enter your password.
          </FormHelperText>
        ) : (
          <FormErrorMessage>Cannot be Blank.</FormErrorMessage>
        )}
        <Button
          mt={4}
          colorScheme='gray'
          type='submit'
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  )
};



export function LogOut( { setUser, setRole, setUid, setSelectedStage } ) {

    const navigate = useNavigate()

    const submitHandler = (response) => {
        setUser('guest');
        setRole('__guest_role__');
        setUid('');
        window.sessionStorage.removeItem('accessToken');
        setSelectedStage('home')
        navigate('/home')     
    }  

    return (
        <Box pt={'60px'} m={4} pl={[2, 8]} maxW={useBreakpointValue({ base: 'xl', md: '3xl' })}>
        <Heading pb={'60px'} color='gray.600' size='md'>Sign Out</Heading>
        <Box>
            <FormLabel>Press Sign Out to confirm, or Cancel</FormLabel>
            <Button
                mt={4}
                colorScheme='gray'
                type='submit'
                onClick={() => { submitHandler() }}
            >
                Sign Out
            </Button>
            <Button
                mt={4}
                ml={12}
                colorScheme='gray'
                type='cancel'
                onClick={() => { navigate(-1) }}
            >
                Cancel
            </Button>
        </Box>
        </Box>
    )
};