

export const restRequest = ( verb, uri, happyCallback, unhappyCallback=null, payload = null, token = null  ) => {

    var accessToken = 'blank';

    const unAuthResponseCodes = [401, 403];
  
    if (token !== null) {
        accessToken = token;
    } else if ('accessToken' in window.sessionStorage) {
        accessToken = window.sessionStorage.getItem('accessToken');
    };
  
    const fetchData = {
        method: verb,
        body: payload ? JSON.stringify(payload) : null,
        headers: new Headers({
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            "Authorization": `Bearer ${accessToken}`
        })
    }
  
    var wasSuccess
    var unauth

    fetch ( uri, fetchData )

        .then((response) => {
            unauth = unAuthResponseCodes.includes(response.status)
            wasSuccess = ( response.status === 200 );
            return response.json()
        })

        .then((data) => {
            if (unauth) {
                accessToken = window.sessionStorage.removeItem('accessToken');
                window.location.href = "/home";
            }
            if (wasSuccess) {
                happyCallback(data)
            } else if (unhappyCallback) {
                unhappyCallback(data)
            } else {
                defaultUnhappy(data)
            }
        })
            
        .catch((error) => {
            console.log( 'Oops', error );
        })
  }

export const defaultUnhappy = (error) => {

    console.log( 'Oops, something went wrong' );
    console.log( error );

};