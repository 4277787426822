import React, {useState, useEffect} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Box, Heading, Text, Grid, GridItem } from "@chakra-ui/react";
import { Button, Input, FormLabel } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";
import { useToastHook } from "./Toast.jsx";
import { useToast } from "@chakra-ui/react";
import { Denied } from './Home.jsx';
import { GET, PUT, successToast, errorToast, BASE_URL, ERROR, ALL_ROLES, ADMIN_ROLE } from "../util/constants.js";
import { restRequest } from "../util/utils.js";



export default function Table ( { cid, group, role }, props ) {

    const toast = useToast()
    const [data, setData] = useState([]);

    var tableUrl = `${BASE_URL}competition/table/?cid=${cid}`
    if (group) {tableUrl = `${tableUrl}&group=${group}`}

    const happyHandler = (response) => {
        setData(response)
    };

    const unhappyHandler = (response) => {
        toast({ title:'Table', description:response.detail, ...errorToast});
    }

    useEffect(() => {restRequest(GET, tableUrl, happyHandler, unhappyHandler);},
        []
    );

    const groupList = [...new Set(data.map(item => item.group))]


    if ( groupList.length === 0) {
        return (
            <Text p={12} color='#e81f81' fontSize='xl' {...props}>
                Tables will be shown once league matches are live
            </Text>
        )
    };

    //console.log(data)

    return (
        <Box maxWidth='100vw' overflow='hidden'>
        {
            groupList.map( (groupName, index) =>{
                return (
                        <Box key={index} m={4}>
                            <Heading width='100%' bg='#f1e8f1' fontSize='xl' p={2} my={4}>
                                {groupName}
                            </Heading>
                            <TableHeading/>
                            <TableItem groupName={groupName} data={data} role={role} />
                        </Box>
                )

            })
        }
        </Box>
    )
};


const TableItem = ( {groupName, data, role} ) => {

    const navigate = useNavigate()

    const handleClick = (tid) => {
        
        if ( ![ADMIN_ROLE].includes( role ) ) {
            return
        }

        navigate(`/lots/${tid}`)
        
    }

    return (
        <>
        {
            data.filter( item => item.group === groupName).map( (teamEntry, index) =>{
                return (
                    <Grid
                        key={index} gap={2}
                        mx={2} px={2}
                        gridTemplateColumns={[
                            "30px 120px 20px 20px 20px",
                            "30px 160px 25px 25px 25px 25px 25px 25px",
                            "30px 200px 30px 30px 30px 30px 30px 30px 35px 35px 35px",
                        ]}
                        gridTemplateAreas={[
                            `"position team_name played diff points"`,
                            `"position team_name played won lost drew diff points"`,
                            `"position team_name played won lost drew for against diff points lots"`
                        ]}
                        fontSize={['.8rem', '.9rem', '1rem']}
                    >
                        <GridItem area='position'>{teamEntry.position}</GridItem>
                        <GridItem area='team_name' onClick={ () => {handleClick(teamEntry.team_f_key)}}>{teamEntry.team_name}</GridItem>
                        <GridItem area='played'>{teamEntry.played}</GridItem>
                        <GridItem area='won' display={['none', 'block', 'block']}>{teamEntry.won}</GridItem>
                        <GridItem area='lost' display={['none', 'block', 'block']}>{teamEntry.lost}</GridItem>
                        <GridItem area='drew' display={['none', 'block', 'block']}>{teamEntry.drew}</GridItem>
                        <GridItem area='for' display={['none', 'none', 'block']}>{teamEntry.goals_for}</GridItem>
                        <GridItem area='against' display={['none', 'none', 'block']}>{teamEntry.goals_against}</GridItem>
                        <GridItem area='diff'>{teamEntry.goal_difference}</GridItem>
                        <GridItem area='points'>{teamEntry.points}</GridItem>
                        <GridItem area='lots' display={['none', 'none', 'block']}>{(teamEntry.lots > 0) ? teamEntry.lots : '-'}</GridItem>
                    </Grid>
                )
            })
        }
        </>
    )
};

const TableHeading = () => {

    return (
        
        <Grid
            gap={2}
            mx={2} px={2}
            gridTemplateColumns={[
                "30px 120px 20px 25px 25px",
                "30px 160px 25px 25px 25px 25px 30px 30px",
                "30px 200px 30px 30px 30px 30px 30px 30px 35px 35px 35px",
            ]}
            gridTemplateAreas={[
                `"position team_name played diff points"`,
                `"position team_name played won lost drew diff points"`,
                `"position team_name played won lost drew for against diff points lots"`
            ]}
            fontWeight='bold'
            fontSize={['.8rem', '.9rem', '1rem']}
        >
            <GridItem area='position'>pos</GridItem>
            <GridItem area='team_name'>team</GridItem>
            <GridItem area='played'>P</GridItem>
            <GridItem area='won' display={['none', 'block', 'block']}>W</GridItem>
            <GridItem area='lost' display={['none', 'block', 'block']}>L</GridItem>
            <GridItem area='drew' display={['none', 'block', 'block']}>D</GridItem>
            <GridItem area='for' display={['none', 'none', 'block']}>F</GridItem>
            <GridItem area='against' display={['none', 'none', 'block']}>A</GridItem>
            <GridItem area='diff'>Diff</GridItem>
            <GridItem area='points'>Pts</GridItem>
            <GridItem area='lots' display={['none', 'none', 'block']}>lots</GridItem>
        </Grid>
    )
};


export const Lots = ( {cid, role} ) => {

    const params = useParams()
    const tid = params.tid
    const toast = useToast()
    const navigate = useNavigate()

    
    const [teamEntry, setTeamEntry] = useState({})
    const [lots, setLots] = useState(0)


    const teamUrl = `${BASE_URL}competition/team/?cid=${cid}&tid=${tid}`

    const happyHandler = (response) => {
        setTeamEntry(response[0])
    };

    const unhappyHandler = (response) => {
        toast({ title:'Table', description:response.detail, ...errorToast});
        navigate('/table')
    }

    useEffect(() => {restRequest(GET, teamUrl, happyHandler, unhappyHandler);},
        []
    );

    const handleInputChange = (e) => {
        //console.log(e)
        setLots(e.target.value)
        //console.log(putData)
    }

    const happySubmit = (response) => {
        navigate('/table')
    }

    const handleSubmit = () => {
        const putData = {cid:cid,tid:tid,lots:lots}
        restRequest(PUT, `${BASE_URL}competition/team/`, happySubmit, unhappyHandler, putData);
    }    

    if ( ![ADMIN_ROLE].includes( role ) ) {
        return (
            <Denied />
        )}  

    return (
        <Box pt={'60px'} m={4} pl={[2, 8]} maxW={['l', 'xl']}>
        <Heading pb={'60px'} color='pink' size='md'>Enter lots score for {teamEntry?.team_name}</Heading>
        <FormLabel>{teamEntry?.team_name} lots</FormLabel>
        <Input mb={4} id='lots' autoFocus={true} size='md' type='integer' value={lots} onChange={handleInputChange} />
        <Button
            mt={4}
            colorScheme='gray'
            type='submit'
            onClick={handleSubmit}
        >
            Submit
        </Button>
        <Button
            mt={4}
            ml={12}
            colorScheme='gray'
            type='cancel'
            onClick={() => { navigate('/table') }}
        >
            Cancel
        </Button>
    </Box>
    )

};