import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { Button, Box, useBreakpointValue } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
// import { SlideDownHeading, FadeInBoxRight } from './Common.jsx';
import { GET, PUT, successToast, errorToast, BASE_URL, noop, ALL_ROLES, ADMIN_ROLE } from "../util/constants.js";
import { restRequest } from "../util/utils.js";
import { Denied } from './Home.jsx';
import StandardTable from './CommonTable.jsx';


// *****************
//  TeamReport
// *****************

export default function TeamReport ( { cid, role } ) {

    const navigate = useNavigate();
    const toast = useToast()
    const [reportData, setReportData] = useState([]);


    const teamUrl = `${BASE_URL}competition/team/?cid=${cid}&sort={'team_name':-1}&short=False`

    const setReportDataFromRest = (response) => {
        setReportData(response)

    };

    const unhappyHandler = (response) => {
        toast({ title:'Team Report', description:`${response.detail}`, ...errorToast});
    }

    useEffect(() => {restRequest(GET, teamUrl, setReportDataFromRest, unhappyHandler);},
        [cid, role]
    );

    if ( ![ADMIN_ROLE].includes( role ) ) {
        return (
            <Denied />
        )}    
    
    const structure = {
        team_name: ['team name', '/team', 'team_f_key'],
        group: ['group', '/team', 'team_f_key'],
        group_index: ['group_index', '/team', 'team_f_key'],
        checked_in: ['checked_in', '/team', 'team_f_key'],
    };

    const title = 'team report';

  
    return (
        <>
        <StandardTable data={reportData} structure={structure} title={title} name='teamReport'/>
        <Button m={6} onClick={ () => { navigate(-1);} }>back</Button>
        </>
    )
    
};


export function UserReport (props) {

    return (
        <Box p={12} fontSize='2xl'>
            User Report page
        </Box>
    )

};


// *****************
//  RefereeReport
// *****************

export function RefereeReport ( { cid, role } ) {

    const navigate = useNavigate();
    const toast = useToast()
    const [reportData, setReportData] = useState([]);


    const teamUrl = `${BASE_URL}competition/referee/?search{'type':'netball'}&sort={'playing_for':-1}`

    const setReportDataFromRest = (response) => {
        setReportData(response)

    };

    const unhappyHandler = (response) => {
        toast({ title:'Referee Report', description:`${response.detail}`, ...errorToast});
    }

    useEffect(() => {restRequest(GET, teamUrl, setReportDataFromRest, unhappyHandler);},
        [cid, role]
    );

    if ( ![ADMIN_ROLE].includes( role ) ) {
        return (
            <Denied />
        )}    
    
    const structure = {
        name: ['name', '/referee', 'id'],
        level: ['level', '/referee', 'id'],
        club: ['club', '/referee', 'id'],
        playing_for: ['playing for', '/referee', 'id'],
        type: ['type', '/referee', 'id'],
    };

    const title = 'referee report';

  
    return (
        <>
        <StandardTable data={reportData} structure={structure} title={title} name='refereeReport'/>
        <Button m={6} onClick={ () => { navigate(-1);} }>back</Button>
        </>
    )
    
};

