import React, {useState, useEffect} from 'react';
import GlobalStyle from './theme/globalStyles.js';
import { ChakraProvider, Flex, theme, Box, extendTheme, keyframes,
  Text, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
//import { Link, VStack, Grid } from '@chakra-ui/react';
import './theme/style.css'

import { Routes, Route, Outlet, useNavigate, Link  } from "react-router-dom";
//import { ColorModeSwitcher } from './ColorModeSwitcher';

import { restRequest } from "./util/utils.js";


// import flyer from './img/CharityBall.png';
// import wideFlyer from './img/LandscapePoster.png';
//import { Logo } from './Logo';

// import { defaultUser } from "./util/constants.js";
import { GET, BASE_URL } from "./util/constants.js";
// import { FadeInBoxLeft, FadeInBoxRight } from './components/Common.jsx';

import StageBar from './components/NavBars.jsx';
import Competition from './components/Competition.jsx';
import Registration from './components/Registration.jsx';
import Groups from './components/Groups.jsx';
import Match from './components/Match.jsx';
import EditMatch from './components/EditMatch.jsx';
import Table, {Lots} from './components/Table.jsx';
import Results from './components/Results.jsx';
import Rules from './components/Rules.jsx';
import Sponsors from './components/Sponsors.jsx';
import Footer from './components/Footer.jsx';
import Home, {Terms, Privacy, PageNotFound, Blank} from './components/Home.jsx';
import TeamReport, { UserReport, RefereeReport } from './components/Report.jsx';
import TeamAdmin, {RefereeAdmin} from './components/Admin.jsx';
import { ActionMenu, Back, Switch } from './components/Menu.jsx';
import { LogIn, LogOut } from './components/LogInLogOut.jsx';
import Netball from "./img/netball192.png";
import Coppafeel from "./img/Coppafeel.png";




function App() {

    const [user, setUser] = useState('guest')
    const [role, setRole] = useState('__guest_role__')
    const [uid, setUid] = useState('')

    const [selectedStage, setSelectedStage] = useState('home');
    const [cstages, setCstages] = useState('[]');
    const [cid, setCid] = useState('')
    const [cname, setCname] = useState('netball')
    const [cstage, setCstage] = useState('')

    const navigate = useNavigate()


    const breakpoints = {
        base: "0px",  //0px
        sm: "440px",  //320px
        md: "640px",  //768px
        lg: "800px",  //992px
        xl: "1000px", //1280px
        "2xl": "1536px",  //1536px
    }
    const customTheme = extendTheme({breakpoints});

    const happyHandler = (data) => {
        setCstages(data[0].competition_stages);
        setCid(data[0].id);
        setCstage(data[0].stage);
        setCname(data[0].name)
    }
    useEffect(() => {restRequest(GET, `${BASE_URL}competition/?name=${cname}&short=true`, happyHandler);},
        [cname]
    );


    return (
        <ChakraProvider theme={customTheme} >
        <GlobalStyle />
        <Routes>
            <Route path="/" element={ <Layout cid={cid} cname={cname} cstage={cstage} cstages={cstages} setCstage={setCstage} user={user} role={role} setSelectedStage={setSelectedStage} selectedStage={selectedStage} /> } >
            <Route path='' element={<Home />} />
            <Route path='home' element={<Home />} />
            <Route path='rules' element={<Rules />} />
            <Route path='competition' element={<Competition role={role} cid={cid} />} />
            <Route path='registration' element={<Registration role={role} cid={cid} />} />
            <Route path='group' element={<Groups cname={cname} role={role} cid={cid} />} />
            <Route path='group_1' element={<Groups cname={cname} role={role} cid={cid} group='/1$/' />} />
            <Route path='group_2' element={<Groups cname={cname} role={role} cid={cid} group='/2$/'/>} />
            <Route path='league' element={<Match name='league' role={role} cid={cid}  match_number='/\d\d\d/'/>} />
            <Route path='league_1' element={<Match name='league'role={role} cid={cid}  match_number='/\d\d\d/' group='/1$/'/>} />
            <Route path='league_2' element={<Match name='league'role={role} cid={cid}  match_number='/\d\d\d/' group='/2$/'/>} />
            <Route path='match/:matchNumber' element={<EditMatch role={role} cid={cid}  /> } />
            <Route path='table' element={<Table role={role} cid={cid} />} />
            <Route path='lots/:tid' element={<Lots role={role} cid={cid} />} />
            <Route path='team/:tid' element={<TeamAdmin role={role} cid={cid} />} />
            <Route path='referee/:rid' element={<RefereeAdmin role={role} cid={cid} />} />
            <Route path='table_1' element={<Table role={role} cid={cid} group='/1$/' />} />
            <Route path='table_2' element={<Table role={role} cid={cid} group='/2$/' />} />
            <Route path='last_16' element={<Match name='last16' role={role} cid={cid}  match_number='/LS/'/>} />
            <Route path='quarters' element={<Match name='quarters' role={role} cid={cid} match_number='/QF/'/>} />
            <Route path='semis' element={<Match name='semis' role={role} cid={cid} match_number='/SF/'/>} />
            <Route path='finals' element={<Match name='finals' role={role} cid={cid} match_number='/FINAL/'/>} />
            <Route path='results' element={<Results cid={cid}  />} />
            <Route path='sponsors' element={<Sponsors />} />
            <Route path='report/team' element={<TeamReport role={role} cid={cid}/>} />
            <Route path='report/referee' element={<RefereeReport role={role} cid={cid}/>} />
            <Route path='report/user' element={<UserReport role={role} cid={cid}/>} />
            <Route path='manage/teams' element={<Blank text='Manage Teams' />} />
            <Route path='user-guide' element={<Blank text='User Guide' />} />
            <Route path='login' element={<LogIn setUser={setUser} setRole={setRole} setUid={setUid} setSelectedStage={setSelectedStage} />} />
            <Route path='logout' element={<LogOut setUser={setUser} setRole={setRole} setUid={setUid} setSelectedStage={setSelectedStage} />} />
            <Route path='terms' element={<Terms />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='back' element={<Back role={role} setCstage={setCstage} cid={cid} setSelectedStage={setSelectedStage}/>} />
            <Route path='switch' element={<Switch role={role} setCstage={setCstage} setCname={setCname} cid={cid} />} />
            <Route path='*' element={<PageNotFound/>} />
            </Route>
        </Routes>
        </ChakraProvider>
    );
};


const Layout = ( {user, cid, cname, role, cstage, setCstage, cstages, selectedStage, setSelectedStage } ) => {

    return (
        <Grid
            minHeight='100vh'
            gridTemplateRows="100px auto 1fr auto"
            gridTemplateColumns="auto 1fr 80px"
            gridTemplateAreas={`
                "logo pageTitle menu"
                "navBar navBar navBar"
                "main main main"
                "footer footer footer"`
            }
        >
            <GridItem area={'logo'} ml={2} alignSelf='center' justifySelf='center' className='no-print'>
                <img width={'120px'} src={Coppafeel}/>
            </GridItem>

            <GridItem area={'pageTitle'} justifySelf='center' alignSelf='center' className='no-print'>
                     <Box color='#ed1f81' fontSize={['xl','2xl','3xl','3xl', '3xl']} fontWeight='bold' ml={2}>Netball Tournament</Box>
            </GridItem>

            <GridItem area={'menu'} alignSelf='center' className='no-print'>
                <ActionMenu cid={cid} cname={cname} role={role} cstage={cstage} setCstage={setCstage} setSelectedStage={setSelectedStage}/>
            </GridItem>

            <GridItem area={'navBar'}  className='no-print' >
                <StageBar role={role} cstages={cstages} setSelectedStage={setSelectedStage} selectedStage={selectedStage} />
            </GridItem>

            <GridItem area={'main'}>
                <Outlet />
            </GridItem>

            <GridItem area={'footer'} className='no-print' >
                <Footer/>
            </GridItem>
        </Grid>
    )
};



export default App;