import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { HStack, FormLabel, FormHelperText, FormErrorMessage, Button, useBreakpointValue } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'  
import { Text, Heading, Box, Input, Icon } from '@chakra-ui/react'  

import { FaFileCsv, FaPlus } from "react-icons/fa6";

import { mongoTest } from "../util/mongoTest.js";
//import { Heading, Button, Button, Box  } from "../styles/commonStyles.js";
import { GET, USER_INFO, INFO, BASE_URL, POST, PUT, DELETE, USER_ERROR, GUEST_INFO, GUEST_ERROR,
  GUEST_ROLE, ADMIN_ROLE, ADMIN_READ_ONLY_ROLE, TREASURER_ROLE, ERROR } from "../util/constants.js";
import { restRequest, onlyDigits, navigateGoBack } from "../util/utils.js";

import { Denied } from './Home.jsx';


function CsvIcon() {
    return <Icon as={FaFileCsv}/>
}

function PlusIcon() {
    return <Icon as={FaPlus}/>
}


///////////////////////////////
//  StandardTable
///////////////////////////////

export default function StandardTable ( { data, structure, title, name=false } ) {


    const navigate = useNavigate();

    const [reportData, setReportData] = useState(data);
    const [filteredData, setFilteredData] = useState([]);
    const [dataLength, setDataLength] = useState(0);

    const [reverse, setReverse] = useState(-1);

    const fields = Object.keys(structure);
    const [sortOn, setSortOn] = useState('');

    const allFields = Object.keys(data);

    let tempFilter = {}
    for (const key of fields) { tempFilter[key] = '';}

    if (name && `${name}-filter` in window.sessionStorage) {
        tempFilter = ( JSON.parse( window.sessionStorage.getItem(`${name}-filter`) ));
    }

    const [myFilter, setMyFilter] = useState(tempFilter);

    /*
    const fmtC = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });
    */



    const handleChange = (e) => {
        const tempFilter = {...myFilter, [e.target.name]: e.target.value};
        if (name) {
        window.sessionStorage.setItem(`${name}-filter`, JSON.stringify(tempFilter))
        }
        setMyFilter(tempFilter);
    }


    const handleSort = (myField) => {
        let savedSort = [['', 1], ['', 1], ['', 1]]
        if (name && name in window.sessionStorage) {
            savedSort = JSON.parse(window.sessionStorage.getItem(name));
        }
        if (sortOn === myField) {
            savedSort = [[savedSort[0][0], reverse * -1], savedSort[1], savedSort[2]]
            setReverse(reverse * -1);
        } else {
            setSortOn(myField)
        if (savedSort[1][0] === myField) {
            savedSort = [[myField, reverse], savedSort[0], savedSort[2]]
        } else if (savedSort[2][0] === myField) {
            savedSort = [[myField, reverse], savedSort[0], savedSort[1]]
        } else {
            savedSort = [[myField, reverse], savedSort[0], savedSort[1]]
        }
        }
        if (name) {
            window.sessionStorage.setItem(name, JSON.stringify(savedSort))
        }
    }

    useEffect(
        () => {
        if (name && name in window.sessionStorage) {
            const savedSort = JSON.parse( window.sessionStorage.getItem(name) );

            if (savedSort[2][0] !== '') {
                data.sort( (a, b) => { return compare(a[savedSort[2][0]], b[savedSort[2][0]], savedSort[2][1]); })
            };

            if (savedSort[1][0] !== '') {
                data.sort( (a, b) => { return compare(a[savedSort[1][0]], b[savedSort[1][0]], savedSort[1][1]); })
            };

            if (savedSort[0][0] !== '') {
                data.sort( (a, b) => { return compare(a[savedSort[0][0]], b[savedSort[0][0]], savedSort[0][1]); })
            };
        };
        setReportData(data);
    },
    [data]
    );

    useEffect(
        () => {
            const temp = [...reportData].sort( (a, b) => {
                return compare(a[sortOn], b[sortOn], reverse);
            });
            setReportData(temp);
        },
        [sortOn, reverse]
    );

    const compare = (first, second, ascDesc) => {
        let nameA = '';
        let nameB = '';
        if (typeof(first) === 'string') {
            nameA = first.toUpperCase();
        } else {
            nameA = first
        }
        if (typeof(second) === 'string') {
            nameB = second.toUpperCase();
        } else {
            nameB = second
        }
        if (nameA < nameB) {
            return ascDesc;
        }
        if (nameA > nameB) {
            return (ascDesc * -1);
        }
        return 0;
    };

    useEffect(
        () => {
            const temp = mongoTest(reportData, myFilter);
            setFilteredData(temp);
            setDataLength(temp.length);
        },
        [reportData, myFilter]
    );

    const buildLink = (data, field) => {
        let path = `${structure[field][1]}`;
        structure[field].map ( (value, index) => {
        if ( value !== '' & index > 1 ) {
            path += `/${data[value]}`
        }
        })
        return (path)
    }

    // https://theroadtoenterprise.com/blog/how-to-download-csv-and-json-files-in-react

    const downloadFile = ({ data, fileName, fileType }) => {
        
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }


    const exportToCsv = e => {
        e.preventDefault()

        const allFields = Object.keys(data[0]);

        // The headers for each column ar our fields

        let reportCsv = data.reduce( (acc, item) => {
            const temp = [];
            //for (const key of fields) { temp.push(item[key])};
            for (const key of allFields) { temp.push(item[key])};
            acc.push(temp.join(','));
            return acc
        }, [])

        downloadFile({
            //data: [fields.join(','), ...reportCsv].join('\n'),
            data: [allFields.join(','), ...reportCsv].join('\n'),
            fileName: 'report.csv',
            fileType: 'text/csv',
        })
    }

    const handleAdd = () => {
        console.log("adding something")
    }


    return (
        <React.Fragment>
        < HStack spacing='24px' float='right' m={4}  >
            <Box onClick={handleAdd} ><PlusIcon width='20px'  /></Box>
            <Box onClick={exportToCsv}><CsvIcon width='20px' /></Box>
        </ HStack >
        { title
            ?
            <Heading m={4} fontSize='lg'>{title}</Heading>
            :
            null
        }
        <Text mx={4} mb={1} fontSize='xs'>{dataLength} rows returned</Text>
        
        <TableContainer clear='both'>
        <Table size='sm' variant='simple' colorScheme='pink' maxWidth='40rem'>
            <Thead>
                <Tr>
                    {
                    fields.map( (field, index) => {
                        return (
                        <Th key={index} onClick={ () => handleSort(field)}>{structure[field][0]} {(field === sortOn) ? '*': ''}</Th>
                        )
                    })
                    }
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                {
                    fields.map( (field, index) => {
                    return (
                        <Td key={index}><Input size='sm' type='text' onChange={handleChange} name={field} value={myFilter[field]}/></Td>
                    )
                    })
                }
                </Tr>
                {
                filteredData.map((data, index)=>{
                    return (
                    <Tr key={index}>
                        {
                        fields.map( (field, index) => {
                            return (
                            <Td key={index}>
                                { structure[field][1] !== ''
                                ?
                                <Link to={ buildLink(data, field) } style={{all: 'unset'}}>
                                    {data[field].toString()}
                                </Link>
                                :
                                data[field].toString()
                                }
                            </Td>
                            )
                        })
                        }
                    </Tr>
                    )
                })
                }
            </Tbody>
        </Table>
        </TableContainer>
        </React.Fragment>
    )
};

